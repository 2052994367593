body {
  background-color: black;
}

.italic {
  font-style: italic;
}

.enter-screen {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
}

.enter-screen__btn {
  font-family: Arial, Helvetica, sans-serif;

  cursor: pointer;
}

.enter-screen__btn:hover {
  text-decoration: underline;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoom {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

.feed {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: fade-in 10s ease-in;
  overflow: hidden;
}

.feed__img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;

  animation: zoom 90s ease-in-out infinite;
}
